import * as React from "react";
import { FC } from "react";
import { useLocalized } from "../../localization/localize";
import classes from "./signature.module.css";

export const Signature: FC = () => (
  <img
    className={classes.signature}
    src={"/words-about-wallpaintings/signature.png"}
    alt={useLocalized("wordsAboutWallpaintings_signature_alt")}
  />
);
