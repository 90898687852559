import * as React from "react";
import { FC } from "react";
import { PolandEntrypoint } from "../../../entrypoints/poland";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.deCH}
    entrypoint={"poland"}
    page={"reise_durch_polen"}
  >
    <PolandEntrypoint lang={LANG.deCH} />
  </DefaultLayout>
);
