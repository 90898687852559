import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import { Migration } from "../common/components/migration/migration";
import { Signature } from "../common/components/signature/signature";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import classes from "../components/poland/poland.module.css";

export const PolandEntrypoint: FC<ILocalizedPage> = _props => {
  const title = useLocalized("poland_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <p>
        {useLocalized("poland_text_1")}
        <br />
        {useLocalized("poland_text_2")}
      </p>
      <div className={classes.imageBlock}>
        <p>
          {useLocalized("poland_text_3")}
          <br />
          {useLocalized("poland_text_4")}
          <br />
          {useLocalized("poland_text_5")}
        </p>
        <figure className={classes.image}>
          <img src={"/poland/st-florian-kirche.jpg"} alt={""} />
          <figcaption>{useLocalized("poland_label")}</figcaption>
        </figure>
      </div>
      <p>
        {useLocalized("poland_text_6")}
        <br />
        {useLocalized("poland_text_7")}
        <br />
        {useLocalized("poland_text_8")}
      </p>
      <Signature />
      <p>{useLocalized("poland_date")}</p>
    </>
  );
};
